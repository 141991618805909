<template> 
    <v-card tile class="mb-3" elevation="5">
        <div class="text-subtitle-1 white--text primary font-weight-bold pa-1 pl-2 pr-2">
            On Air Timer
        </div>
        <v-row id="on-air-time" class="ma-0 pa-3" align="center" justify="center">
            <v-col cols="12" class="col-lg-6 text-h4 font-weight-bold" align="center" justify="center">
                <div class="stop-watch-timer">
                    <span v-if="!prodGetOptLoading">{{ onAirTime | secondsInMinutes }}</span>
                    <v-skeleton-loader v-if="prodGetOptLoading"
                        v-bind="{class: 'skeleteon-loader'}"
                        type="list-item"
                    ></v-skeleton-loader>
                </div>
            </v-col>
            <v-col cols="12" class="col-lg-6" align="center" justify="center">
                    <v-btn fab text class="mr-2" color="info" :disabled="timerState === 1"
                    @click="toggleOnAirTime(1)">
                        <v-icon dark x-large color="info">mdi-play-circle-outline</v-icon>
                </v-btn>
                    <v-btn fab text class="mr-2" color="info" :disabled="timerState === 0 || timerState === null"
                    @click="toggleOnAirTime(0)">
                        <v-icon dark x-large color="info">mdi-pause-circle-outline</v-icon>
                </v-btn>
                    <v-btn fab text class="mr-2" color="info" :disabled="timerState === null"
                    @click="toggleOnAirTime(null)">
                        <v-icon dark x-large color="info">mdi-close-circle-outline</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import globalHelper from '@/helpers/global.helper';

export default {
    data: (vm) => ({
        timerInterval:null,
        onAirTime: 0,
        timerState: null,
    }),
    computed: {
        ...mapGetters(["prodGetOptLoading", "onAirTimerModel"]),
    },
    watch: {
        timerState: function (newValue) {
            if (newValue === 1) {
                clearInterval(this.timerInterval);
                this.startTime();
            } else if (newValue === 0) {
                this.stopTime();
            } else {
                this.resetTime();
            }
        },
        onAirTimerModel: function (newValue) {
            if(newValue) this.onairTimerUpdate(newValue);
        }
    },
    filters: {
        secondsInMinutes: function(seconds) {
            return globalHelper.givenSecToTime(seconds);
        }
    },
    created() {
        const reqData = {
            name: 'search',
            value: {
                option_name: ['ON_AIR_TIMER'],
            }
        }
        this.getOptionsData(reqData).then((response) => {
            if (response.data.data.ON_AIR_TIMER) {
                this.onairTimerUpdate(response.data.data.ON_AIR_TIMER);
            }
        });
    },
    mounted() {
        this.wsOnAirTimerUpdate();
    },
    methods: {
        ...mapActions(["updatesOptionsData", "getOptionsData"]),

        startTime() {
            this.timerInterval = setInterval(() => {
                this.onAirTime++;
            }, 1000);
        },
        stopTime() {
            clearInterval(this.timerInterval);
        },
        resetTime() {
            this.onAirTime = 0;
            clearInterval(this.timerInterval);
        },
        toggleOnAirTime(status=null) {
            const reqData = {
                value: status === null ? null : {
                    state: status,
                    elapsed: this.onAirTime
                },
                name: 'ON_AIR_TIMER'
            }
            if (status && status === 1) {
                reqData.value['init'] = this.$helpers.getMomentDatas('YYYY-MM-DD HH:mm:ss');
            }
            this.updatesOptionsData(reqData);
        },
        onairTimerUpdate(timerModel) {
            this.onAirTime = timerModel.elapsed;
            if (timerModel.state === 1) {
                const diffElapsed = this.$helpers.getMomentDiff(timerModel.init, 'seconds');
                this.onAirTime = timerModel.elapsed + diffElapsed;
            }
            this.timerState = timerModel.state;
        },
        wsOnAirTimerUpdate() {
            Echo.channel('ON_AIR_TIMER').listen('.saved',(onAirModel) => {
                if (onAirModel.data) {
                    this.onairTimerUpdate(onAirModel.data);
                } else {
                    this.timerState = null;
                }
            });
        },
    },
    beforeDestroy() {
        Echo.leaveChannel('ON_AIR_TIMER');
        if (this.timerInterval) {
            clearInterval(this.timerInterval);
            this.timerInterval = null;
        }
    }
}
</script>
